import Vue from "vue";
import SelectCountry from "@/views/Admin/Persons/V10/Dependencies/Select/Country/index.vue";
import SelectStrategy from "@/views/Admin/Persons/V10/Dependencies/Select/Strategy/index.vue";
import SelectUseCase from "@/views/Admin/Persons/V10/Dependencies/Select/UsesCases/index.vue";
import SelectGeo from "@/views/Admin/Persons/V10/Dependencies/Select/Geo/index.vue";
import SelectPois from "@/views/Admin/Persons/V10/Dependencies/Select/Pois/index.vue";
import SelectPrivate from "@/views/Admin/Persons/V10/Dependencies/Select/Private/index.vue";
import SelectOoh from "@/views/Admin/Persons/V10/Dependencies/Select/OOH/index.vue";
import SelectPos from "@/views/Admin/Persons/V10/Dependencies/Select/Pos/index.vue";
import CardCollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CardCollapsePanel/index.vue";
import GeoAnalysis from "@/views/Admin/Persons/V10/Dependencies/Carto/Pois/index.vue";
import GraphicContent from "@/views/Admin/Persons/V10/Dependencies/Common/Graphic/Content/index.vue";
import AudienceExport from "@/views/Admin/Persons/V10/Dependencies/Dialogs/ExportAudience/index.vue";
import SavePois from "@/views/Admin/Persons/V10/Dependencies/Dialogs/SavePois/index.vue";
import GeoFencing from "@/views/Admin/Persons/V10/Dependencies/Dialogs/GeoFencing/index.vue";
import ActivateOOH from "@/views/Admin/Persons/V10/Dependencies/Dialogs/ActivateOOH/index.vue";
import { getExpansionPane } from "@/views/Admin/Persons/V10/Dependencies/Utils";
import { Notification } from "@/interfaces/proccess";
import { isEmpty, isEqual } from "lodash";
import { mapActions, mapGetters } from "vuex";
import {
	ActivePanelTab,
	AnalyzeAudienceType,
	ButtonActionType,
	GraphicDataKey,
	GraphicDataType,
	PersonKey,
	PersonStrategyKey,
	PersonUsesCasesKey,
} from "@/interfaces/persons/v10/types";
import CardAction from "@/views/Admin/Persons/V10/Dependencies/Common/Buttons/CardAction/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import { ActionKeyof } from "@/interfaces/persons/v10/route";
import {
	ConfigPost,
	ResultContinent,
	ResultData,
} from "@/interfaces/persons/v10/response";
import { ElementData, StoreAttribution } from "@/interfaces/persons/v10/person";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { ItemGraphic } from "@/interfaces/graphic";
import { convLocaleString } from "@/utils/convert";
import { graphicDataItems } from "./utils";
import {
	ButtonActionEntity,
	getFilteredActions,
} from "@/models/persons/v10/Tabs/Pois/ButtonAction";
import AppStoreAttribution from "@/views/Admin/Persons/V10/Dependencies/Dialogs/StoreAttribution/index.vue";
import { ConfigPostEntity } from "@/models/persons/v10/response";
import notificationService from "@/services/notification-service";
import store from "@/store";
import {
	ActionResponseEntity,
	ActivePanelNextEntity,
	ExpansionPanelEntity,
	SelectedDataEntity,
	SluggableEntity,
	TabSelectionOptionalEntity,
	searchVisibility,
} from "@/models/persons/v10/Implements";
import { PersonEntity } from "@/models/persons/v10/Person";
import { CampaingList } from "@/interfaces/campaign";
import { COUNTRY_DEFAULT } from "@/models/persons/v10/DataDefault";
import { catchError } from "@/store/Util";
import { SnackNotificationEntity } from "@/models/Snack";
import { SnactType } from "@/interfaces/snack";
import { isBackFromAudience } from "@/utils/services-global";
import {
	getGraphicSource,
	isLoadingSource,
	getCountReachItems,
} from "@/store/Modules/Persons/v10/graphic-utils";
import { removeFromStorage } from "@/services/storage-service";
import { getPermisionPlanning } from "@/utils/permissionResolve";
import { AxiosError } from "axios";

export default Vue.extend({
	name: "TabPois",
	props: {},
	components: {
		CardCollapsePanel,
		SelectCountry,
		SelectStrategy,
		SelectUseCase,
		SelectGeo,
		SelectPois,
		SelectPos,
		SelectPrivate,
		SelectOoh,
		CardAction,
		GeoAnalysis,
		GraphicContent,
		SnackMessage,
		CardGrouped,
		AppStoreAttribution,
		AudienceExport,
		SavePois,
		GeoFencing,
		ActivateOOH,
	},
	data: () => ({
		itemsExpansion: [] as ExpansionPanelEntity[],
		activePanel: ActivePanelTab.STRATEGY,
		isGettingFromStored: false,
		snackData: new SnackNotificationEntity(),
		noResetOnDestruye: false,
	}),
	created() {
		this.$nextTick(async () => {
			await this.initializeData();

			// DEBUG ACTIVATE_OOH
			//await this.setButtonActionDisabled(ButtonActionType.ACTIVATE_OOH, false);
		});
	},
	mounted() {},
	destroyed() {
		if (this.noResetOnDestruye) return;
		this.resetPerson({ country: this.getPerson.getCountry() });
		this.initPoisActions();
		removeFromStorage("sections");
	},
	computed: {
		...mapGetters("person", [
			"getPerson",
			"getLikeAll",
			"getResponseData",
			"getQueryParams",
			"getPoisActions",
			"getStoreAttribution",
			"getPersistent",
			"getCountry",
			"getCampaigns",
			"getResource",
			"isBackFromAudience",
		]),

		...mapGetters("campaign", ["getCampaignList"]),
		...mapGetters("profile", ["account", "getAbility"]),
		...mapGetters("audience", ["getPoisCount"]),

		canCreateOOHNotification() {
			return this.getAbility.can(
				this.getPermission.actions.create_ooh_notification,
				this.getPermission.subject
			);
		},

		getItemsExpansion(): ExpansionPanelEntity[] {
			return this.itemsExpansion;
		},

		getSectionTabs() {
			const tabs: PersonKey[] = this.getPersonComputed.visibility.tabs;
			const items: ExpansionPanelEntity[] = this.getItemsExpansion;
			return items.filter((i) => tabs.includes(i.type));
		},

		getPermission() {
			return getPermisionPlanning();
		},

		getPersonComputed(): PersonEntity {
			return this.getPerson;
		},

		getCampaignListComputed(): CampaingList[] {
			return this.getCampaignList;
		},

		getGraphicDataGeo() {
			return graphicDataItems.geo(
				this.getPerson.getCountryCode("id", "string")
			);
		},

		getGraphicDataPois() {
			return graphicDataItems.pois(
				this.getPerson.getCountryCode("id", "string")
			);
		},

		getGraphicDataItems() {
			return graphicDataItems;
		},

		isAnalizedGeo() {
			return this.getPerson.isAnalizedGeo();
		},

		isAnalizedPois() {
			return this.getPerson.isAnalizedPois();
		},

		getCountPois() {
			const total: number = this.getPoisCount.getTotal();
			return [{ count: convLocaleString(total) }];
		},

		getCountReach() {
			const typeReach = this.getPerson.getAudienceType();
			return this.getCountReachItems(typeReach);
		},

		showCountPois() {
			return this.getPoisCount.isFetched();
		},

		showCountReach() {
			return !isEmpty(this.getCountReach);
		},

		getActivePanelTab() {
			return ActivePanelTab;
		},

		getActions() {
			let actions: ButtonActionEntity[] = this.getPoisActions;

			/**
			 * No mostrar el boton ACTIVATE_OOH si no cumple las condiciones
			 * definidas en: showActivateOOHButton
			 */
			const showActivateOOHButton: Boolean =
				this.getPerson.showActivateOOHButton();

			if (!this.canCreateOOHNotification || !showActivateOOHButton) {
				actions = actions.filter(
					(a) => a.type !== ButtonActionType.ACTIVATE_OOH
				);
			}

			return getFilteredActions(actions, this.showButton);
		},

		getButtonActionType() {
			return ButtonActionType;
		},

		getPersonKey() {
			return PersonKey;
		},

		getGraphicDataType() {
			return GraphicDataType;
		},

		getGraphicDataKey() {
			return GraphicDataKey;
		},
	},
	methods: {
		...mapActions("person", [
			"resetPerson",
			"fetchGetGraphicData",
			"fetchTableName",
			"fetchAnalizeAudienceByType",
			"fetchAnalizeAudience",
			"postData",
			"fetchCalculateReach",
			"initPoisActions",
			"fetchStoreAttribution",
			"fetchData",
			"setResource",
			"savePois",
			"saveGeoFencing",
			"saveDataToStorage",
			"getSavedDataToStorage",
			"clearDataToStorage",
		]),
		...mapActions("audience", {
			clearAudienceFilters: "clearFilters",
			fetchPoisCount: "fetchPois",
			exportAudience: "exportAudience",
		}),

		...mapActions("campaign", ["getStoreAttributions"]),

		handleCollapseRemove(event: any) {
			// console.debug("handleCollapseRemove", event);
		},

		async isValidSavedData(data: { [x: string]: any[] | undefined }) {
			const keys = ["person", "resources", "items", "pois_actions"];
			return keys.every((key) => data[key] !== undefined);
		},

		/**
		 * Validar si existen datos en el storage
		 * @returns
		 */
		async hasSavedDataStorage(dataStore: {
			[x: string]: any[] | undefined;
		}) {
			return await this.isValidSavedData(dataStore);
		},

		/**
		 * Obtener datos desde el storage
		 */
		async retrieveFromStorage() {
			this.isGettingFromStored = true;
			const dataStore = await this.getSavedDataToStorage();
			const { items } = dataStore;
			if (!this.hasSavedDataStorage(dataStore)) {
				this.itemsExpansion = await getExpansionPane();
				console.error("Could not recover data from storage");
				throw new Error("Could not recover data from storage");
			}
			this.itemsExpansion = items;
			await this.getPerson.updateVisibility();
			await this.fetchPoisCount({ breakdown: true });
			this.handleSubmitCarto();
			this.isGettingFromStored = false;
		},

		/**
		 * Obtener datos limpios
		 */
		async getCleanData() {
			this.removeParamsFromURL();

			this.getPerson.changeTabSelection(
				new TabSelectionOptionalEntity({
					strategy: true,
					uses_cases: true,
					geo: true,
					pois: true,
					ooh: true,
					pos: true,
					privates: true,
				})
			);

			this.itemsExpansion = await getExpansionPane();

			await this.fetchCountries();
			await this.setCountry();
			await this.fetchStrategy();
			await this.fetchUseCases();

			await this.getPerson.updateVisibility();
		},

		async initializeData() {
			this.noResetOnDestruye = false;
			this.getPoisCount.resetFetch();

			const isBack: Boolean = isBackFromAudience();

			/**
			 * Si hay parametro: ?from=audience
			 */
			if (!isBack) {
				await this.getCleanData();
				return;
			}

			/**
			 * Obtener los datos del storage
			 */
			const dataStore = await this.getSavedDataToStorage();
			const hasData: Boolean = await this.hasSavedDataStorage(dataStore);

			/**
			 * Si no hay datos en el storage, obtener datos limpios
			 */
			if (!hasData) {
				await this.getCleanData();
				return;
			}

			/**
			 * Obtener datos del storage
			 */
			await this.retrieveFromStorage();
		},

		fetchStrategy() {
			return new Promise(async (resolve) => {
				// Fetch all strategies
				const result: SluggableEntity[] = await this.fetchData({
					type: "strategies",
				});

				await this.setResource({
					key: "api_strategies",
					value: result,
				});

				// const resource: SluggableEntity[] = this.getResource({
				// 	key: "api_strategies",
				// });

				resolve(result);
			});
		},

		fetchUseCases() {
			return new Promise(async (resolve, reject) => {
				try {
					const person: PersonEntity = this.getPerson;

					const payload = {
						country_code: person.country_global.id,
					} as ConfigPost;

					const result: SluggableEntity[] = await this.postData({
						type: "use_cases",
						body: payload,
					});

					await this.setResource({
						key: "api_use_cases",
						value: result,
					});

					resolve(result);
				} catch (errors) {
					const typedError = errors as AxiosError;
					const isCancel: boolean = (errors as any)?.isCancel;
					if (isCancel) return;
					console.error(
						`${this.$options.name}::fetchUseCases`,
						typedError
					);
					resolve(false);
				}
			});
		},

		fetchCountries() {
			return new Promise(async (resolve) => {
				this.getPoisCount.resetFetch();

				// Fetch all countries
				const result: ResultContinent[] = await this.fetchData({
					type: "countries",
				});

				await this.setResource({
					key: "countries",
					value: result,
				});

				resolve(result);
			});
		},

		setCountry() {
			return new Promise(async (resolve) => {
				// Fetch all countries
				const result: ResultContinent[] = this.getResource({
					key: "countries",
				});

				// flat countries result
				const flatCountries: ElementData[] = result
					.map((r) => r.items)
					.flat();

				// destructuring country from account
				const { numeric_code, name } = this.account.country;

				// has country account -> flatCountries
				const hasCountry: Boolean = !!flatCountries.find(
					(c) => c.id == numeric_code
				)?.id;

				const countryGlobal: ElementData = hasCountry
					? { id: parseInt(numeric_code), value: name }
					: COUNTRY_DEFAULT;

				await this.getPerson.setByKey(
					PersonKey.COUNTRY_GLOBAL,
					countryGlobal
				);

				this.getQueryParams.setCountryCode(countryGlobal.id);

				resolve(countryGlobal);
			});
		},

		getGraphicSource(type: GraphicDataType, key: GraphicDataKey) {
			return getGraphicSource(type, key);
		},

		isLoadingSource(type: GraphicDataType, key: GraphicDataKey) {
			return isLoadingSource(type, key);
		},

		getCountReachItems(key: GraphicDataKey) {
			return getCountReachItems(key);
		},

		getCount(key: string) {
			switch (key) {
				case ButtonActionType.ANALYZE_POIS:
					return this.getPoisCount.preparePoisCount();
				case ButtonActionType.CALCULATE_REACH:
					return this.getCountReach;
				case ButtonActionType.STORE_ATTR:
					return this.getPerson.store_attribution.getMaidUnique();
				default:
					return [];
			}
		},

		getChipColor(key: string) {
			switch (key) {
				case ButtonActionType.ANALYZE_POIS:
					return this.getPoisCount.isValid() ? "indigo" : "red";
				default:
					return "indigo";
			}
		},

		showReach(key: ButtonActionType): boolean {
			switch (key) {
				case ButtonActionType.ANALYZE_POIS:
					return this.showPoisCount(key);
				case ButtonActionType.CALCULATE_REACH:
					return this.showCalculateReach(key);
				case ButtonActionType.STORE_ATTR:
					return this.getPerson.store_attribution.showMaidUnique();
				default:
					return false;
			}
		},

		showPoisCount(key: string) {
			return key === ButtonActionType.ANALYZE_POIS && this.showCountPois;
		},

		showCalculateReach(key: string): Boolean {
			return (
				key === ButtonActionType.CALCULATE_REACH && this.showCountReach
			);
		},

		isSection(itemType: PersonKey, type: PersonKey) {
			return itemType === type;
		},

		handleInput(params: any) {
			//TODO on filter
		},

		isTabActive(tabIndex: ActivePanelTab) {
			return tabIndex === this.activePanel;
		},

		showSection(item: ExpansionPanelEntity) {
			return this.getPersonComputed.visibility.showTab(item.type);
		},

		isExpanded(itemId: number) {
			return this.activePanel === itemId;
		},

		showButton(actionType: ButtonActionType) {
			/**
			 * Prevent Analyze button to be showed if
			 * table_id is setted. This prevent the user
			 * to get confused with analyze button
			 * in the map.
			 * 
			 * It's restored if any filter is changed
			 */
			const isAnalyzeButton = [
				ButtonActionType.ANALYZE_GEO, 
				ButtonActionType.ANALYZE_POIS, 
				ButtonActionType.ANALYZE_POS
			].includes(actionType);

			if(isAnalyzeButton && Boolean(this.getPersonComputed.table_id) && !Boolean(this.getPersonComputed.loading.action[actionType])) return false;

			return this.getPersonComputed.visibility.showButton(actionType);
		},

		async fetchAllAnalyze(type: ActionKeyof): Promise<void> {
			const actionType: ButtonActionType =
				ButtonActionType[type.toUpperCase()];

			if (actionType === undefined) {
				console.error(`Unknown action type: ${type}`, { actionType });
				return;
			}

			await this.processAnalize(type, actionType);
		},

		async processAnalize(type: ActionKeyof, actionType: ButtonActionType) {
			try {
				// Obtener el payload con los filtros seleccionados
				const postBody: ConfigPostEntity =
					await this.getPerson.getPostBodyForAnalize();

				this.snackData.setSnackData({
					type: SnactType.SECONDARY,
					message: this.$t("loadings.loading"),
				});

				// Fetch: Analize Pois|Geo|Pos
				const resultData: ResultData = await this.postData({
					type: actionType,
					body: postBody,
				});

				// Send data to carto
				await this.fetchTableName();

				// Instancia de ActionResponse
				let analizeData: ActionResponseEntity = this.getPerson.response;

				// Guardar el response de Analize Pois
				analizeData.setResponse(actionType, resultData);

				const isActionPois: boolean =
					actionType === ButtonActionType.ANALYZE_POIS;

				// Datos de Pois Count
				if (isActionPois) {
					this.getPoisCount.resetFetch();
				}

				let isValidResponse: Boolean =
					analizeData.isValidResponse(actionType);

				if (actionType === ButtonActionType.ANALYZE_POIS) {
					await this.fetchPoisCount({ breakdown: true });

					// Comprobar si el PoisCount y el response de AnalizePois son validos
					isValidResponse =
						isValidResponse && this.getPoisCount.isValid();
				}

				await this.setButtonActionDisabled(
					ButtonActionType.CALCULATE_REACH,
					!isValidResponse
				);
				await this.setButtonActionDisabled(
					ButtonActionType.SAVE_POIS,
					!isValidResponse
				);
				await this.setButtonActionDisabled(
					ButtonActionType.GEO_FENCING,
					!isValidResponse
				);
				await this.setButtonActionDisabled(
					ButtonActionType.STORE_ATTR,
					!isValidResponse
				);
				await this.setButtonActionDisabled(
					ButtonActionType.ACTIVATE_OOH,
					!isValidResponse
				);

				if (isValidResponse) {
					// save data to state&localStorage
					this.getPersistent.setCountry(
						this.getPerson.country_global
					);

					if (isActionPois) {
						this.fetchGetGraphicData(true);

						this.snackData.setSnackData({
							type: SnactType.INFO,
							message: this.$t("loadings.loading_graphics"),
						});
					}

					this.handleSubmitCarto();
				} else {
					// The amount exceed the limit, then send an error
					notificationService.notifyWarning(store, {
						message:
							this.getPoisCount.getTotal() === 0
								? this.$t("Persons10.errors.pois_no_data")
								: this.$t("Persons10.errors.pois_exceed", {
										maxPois: convLocaleString(
											this.getPoisCount.maxPois
										),
								  }),
					} as Notification);
				}

				this.setButtonLoading(type, false);
			} catch (error) {
				console.error(`${this.$options.name}::processAnalize`, {
					error,
				});
				catchError(store, error);
				this.setButtonLoading(actionType, false);
			}
		},

		async actionNotAllowed(type: ActionKeyof) {
			this.snack_color = "orange darken-3";
			this.message = this.$t(
				"persons.v10.panelsPois.messages.one_time_action",
				{
					name: this.$t(`persons.v10.panelsPois.button.${type}`),
				}
			);
			this.setButtonLoading(type, false);
		},

		async syncGeoAnalysis() {
			const type: ButtonActionType | null = this.getPerson.getTypeAudience();
			if(type) {
				this.handleAction({ type });
			}
		},

		async handleAction(params: { type: ButtonActionType }) {
			const { type } = params;
			this.setButtonLoading(type, true);

			switch (type) {
				case ButtonActionType.ANALYZE_POIS:
					if (this.showReach(type)) {
						this.actionNotAllowed(type);
						break;
					}
					await this.updateFiltersClear();
					await this.fetchAllAnalyze(type);
					this.clearAudienceFilters();
					break;

				case ButtonActionType.ANALYZE_GEO:
					await this.updateFiltersClear();
					await this.fetchAllAnalyze(type);
					this.clearAudienceFilters();
					break;

				case ButtonActionType.ANALYZE_POS:
					await this.updateFiltersClear();
					await this.fetchAllAnalyze(type);
					this.clearAudienceFilters();
					break;

				case ButtonActionType.CALCULATE_REACH:
					if (this.showReach(type)) {
						this.actionNotAllowed(type);
						break;
					}
					let result: ItemGraphic[] =
						await this.fetchCalculateReach();
					this.setButtonLoading(type, false);
					if (!result?.length) break;
					await this.setButtonActionDisabled(
						ButtonActionType.TARGET_AUDIENCE,
						false
					);
					await this.setButtonActionDisabled(
						ButtonActionType.STORE_ATTR,
						true
					);

					/**
					 * Habilitar el boton siguiente segun
					 */
					this.setNextButtonDisabled(
						ButtonActionType.CALCULATE_REACH,
						false
					);

					break;

				case ButtonActionType.ANALYZE_AUDIENCE:
					await this.generateAnalizeAudience();
					this.setButtonLoading(type, false);
					await this.saveDataToStorage({
						items: this.itemsExpansion,
					});
					break;

				case ButtonActionType.STORE_ATTR:
					this.handleStoreAttributionClose(true);
					this.setButtonLoading(type, false);
					break;

				case ButtonActionType.SAVE_POIS:
					this.handleSavePoisClose(true);
					this.setButtonLoading(type, false);
					break;

				case ButtonActionType.TARGET_AUDIENCE:
					//TODO Target Audience
					this.handleTargetAudienceClose(true);
					this.setButtonLoading(type, false);
					break;

				case ButtonActionType.GEO_FENCING:
					this.handleGeoFencingClose(true);
					this.setButtonLoading(type, false);
					break;

				case ButtonActionType.ACTIVATE_OOH:
					this.handleActivateOOHClose(true);
					this.setButtonLoading(type, false);
					break;
			}
		},

		async generateAnalizeAudience() {
			this.noResetOnDestruye = true;

			const audienceType = this.getPerson.getAudienceType();
			/**
			 * Fetch:
			 * Analize Audience por {audienceType}
			 */
			/*
			const result: ResultData = await this.fetchAnalizeAudienceByType({
				type: audienceType,
			});*/

			const result: ResultData = await this.fetchAnalizeAudience();

			const isStoreAttribution: Boolean =
				this.getPerson.store_attribution.showMaidUnique();

			/**
			 * Si la respuesta es invalida mostrar alerta tipo snack
			 */
			if (!result || !result.response) {
				this.snackData.setSnackData({
					type: SnactType.ERROR,
					message: this.$t("snack.error.generate_audience"),
				});
				return;
			}

			const routeName = isStoreAttribution
				? "PersonsStoreAttribution"
				: "PersonsAudience";

			this.scrollToTop();

			const person: PersonEntity = this.getPerson;

			return this.$router.push({
				name: routeName,
				query: {
					strategy: person.strategy.type,
					use_cases: person.uses_cases.value,
				},
			});
		},

		async handleSubmitCarto() {
			const refs = this.$refs;
			let componentCarto = refs.pois_carto;

			await componentCarto.sendAllFilters();
		},

		isButtonActionShown(_type: ButtonActionType) {
			const strategyType: PersonStrategyKey =
				this.getPerson.strategy.value;

			const useCaseType: PersonUsesCasesKey =
				this.getPerson.uses_cases.value;

			const useCase = searchVisibility(strategyType, useCaseType);
			return Boolean(useCase?.buttons.includes(_type));
		},

		/**
		 * Habilitar el boton siguiente segun
		 * @param type
		 * @param disabled
		 */
		async setNextButtonDisabled(type: ButtonActionType, disabled: Boolean) {
			let _next: ButtonActionType =
				await this.getPerson.getNexButtonAction(type);
			await this.setButtonActionDisabled(_next, disabled);
		},

		async setButtonActionDisabled(
			type: ButtonActionType,
			disabled: Boolean
		) {
			const actions: ButtonActionEntity[] = this.getPoisActions;
			const index = actions.findIndex((a) => a.type === type);
			if (index < 0) return;
			this.getPoisActions[index].setDisabled(disabled);
		},

		setButtonLoading(type: string, loading: Boolean = false) {
			const actions: ButtonActionEntity[] = this.getPoisActions;
			const index = actions.findIndex((a) => a.type === type);
			if (index < 0) return;
			this.getPoisActions[index].setLoading(loading);
		},

		disabledExpansionButton(type: PersonKey, disabled: Boolean) {
			this.getPoisCount.resetFetch();
			let expansion: ExpansionPanelEntity = this.itemsExpansion.find(
				(e: { type: PersonKey }) => e.type === type
			);
			if (!expansion) return;
			expansion.disabled = disabled;
		},

		async handleStoreAttributionClose(open: Boolean = false) {
			try {
				this.getPerson.store_attribution.setLoading(open);
				this.getPerson.store_attribution.setOpen(open);
				if (open) {
					await this.getStoreAttributions();
					this.getPerson.store_attribution.setLoading(false);
				}
			} catch (error) {
				this.getPerson.store_attribution.setLoading(false);
			}
		},

		handleTargetAudienceClose(open: Boolean = false) {
			this.getPerson.target_audience.setOpen(open);
		},

		handleSavePoisClose(open: Boolean = false) {
			this.getPerson.save_pois.setOpen(open);
		},

		handleStoreAttributionCheck(check: Boolean = false) {
			this.getPerson.store_attribution.setCheck(check);
		},

		handleGeoFencingClose(open: Boolean = false) {
			this.getPerson.geo_fencing.setOpen(open);
		},

		handleActivateOOHClose(open: Boolean = false) {
			this.getPerson.activate_ooh.setOpen(open);
		},

		async handleStoreAttributionSubmit() {
			try {
				const storeAttibution: StoreAttribution =
					this.getPerson.store_attribution;

				const dataParams = {
					country_code: this.getPerson.getCountryCode("id", "string"),
					campaign_ids: storeAttibution.selected.map((s) => s.id),
					check: Boolean(this.getPerson.store_attribution.check),
				};

				this.getPerson.store_attribution.setLoading(true);

				this.getPersistent.clearCampaigns();

				const result: ResultData = await this.fetchStoreAttribution(
					dataParams
				);

				this.getPerson.store_attribution.setResponse(result);

				// save data to state&localStorage
				this.getPersistent.setCampaigns(storeAttibution.selected);

				await this.setButtonActionDisabled(
					ButtonActionType.CALCULATE_REACH,
					true
				);
				await this.setButtonActionDisabled(
					ButtonActionType.ANALYZE_AUDIENCE,
					false
				);

				await this.setButtonActionDisabled(
					ButtonActionType.TARGET_AUDIENCE,
					false
				);

				this.getPerson.store_attribution.setLoading(false);
				this.getPerson.store_attribution.setOpen(false);
			} catch (error) {
				this.getPerson.store_attribution.setLoading(false);
				this.getPersistent.clearCampaigns();
				console.error("handleStoreAttributionSubmit", error);

				if (error instanceof Error) {
					// 👉️ err is type Error here
					console.error(error.message);
				}

				console.error("Unexpected error", error);
			}
		},

		async handleTargetAudienceSubmit(data) {
			this.exportAudience(data);
			this.handleTargetAudienceClose();
		},

		async handleSavePoisSubmit(data) {
			this.savePois(data);
			this.handleSavePoisClose();
		},

		async handleGeoFencingSubmit(data) {
			this.saveGeoFencing({
				...data,
				country_code: this.getCountry.id,
			});
			this.handleGeoFencingClose();
		},

		// update on changed any filter
		async updateFiltersClear() {
			const person: PersonEntity = this.getPerson;

			const isAnalized: Boolean = person.isAnalized();

			const showCountPois: Boolean = this.showCountPois;

			if (!isAnalized && !showCountPois) return;

			this.getPoisCount.resetFetch();

			// reset buttons actions
			await this.setButtonActionDisabled(
				ButtonActionType.CALCULATE_REACH,
				true
			);
			await this.setButtonActionDisabled(
				ButtonActionType.STORE_ATTR,
				true
			);
			await this.setButtonActionDisabled(
				ButtonActionType.ANALYZE_AUDIENCE,
				true
			);
			await this.setButtonActionDisabled(
				ButtonActionType.SAVE_POIS,
				true
			);
			await this.setButtonActionDisabled(
				ButtonActionType.TARGET_AUDIENCE,
				true
			);
			await this.setButtonActionDisabled(
				ButtonActionType.GEO_FENCING,
				true
			);
			await this.setButtonActionDisabled(
				ButtonActionType.ACTIVATE_OOH,
				true
			);

			await this.getPerson.changeFilters();
			await this.clearDataToStorage();
		},

		/**
		 * Verificar si los filtros en cada tab es valido
		 * si es valido cierra el tab actual y abre el proximo
		 * si retorna undefined no setear ningun valor
		 * @param personKey
		 */
		async verifyActivePanelByKey(
			personKey: PersonKey,
			val?: SelectedDataEntity
		) {
			let result: ActivePanelNextEntity =
				await this.getPerson.verifyActivePanel(
					this.activePanel,
					personKey
				);

			if (!result) return;

			const { enable, activePanel } = result;

			if (result) {
				// Actualizar Active Panel
				this.activePanel = activePanel;
			}

			if (enable) {
				// Habilitar/Deshabilitar boton
				this.disabledExpansionButton(enable, val && isNaN(val.id));
			}

			await this.updateButtonActionDisable(false);
		},
		scrollToTop() {
			document
				.querySelectorAll(".layout-main")
				.forEach((item) => item.scrollTo({ top: 100 }));
		},
		removeParamsFromURL() {
			const isBack: Boolean = isBackFromAudience();
			if (isBack) {
				this.$router.replace({
					name: "PersonsIndex",
				});
			}
		},

		async updateButtonActionDisable(verify: boolean = true) {
			const analyzeActions = [
				ButtonActionType.ANALYZE_POIS,
				ButtonActionType.ANALYZE_GEO,
				ButtonActionType.ANALYZE_POS,
			];

			analyzeActions.map(async (actionType) => {
				await this.setButtonActionDisabled(actionType, true);
			});

			if (!verify) return;

			const actionType: ButtonActionType =
				this.getPerson.getAnalizeButtonActionType();
			const disableButton: boolean = !this.getPerson.enableAnalize();

			await this.setButtonActionDisabled(actionType, disableButton);
		},

		async updateExpansionSections() {
			this.disabledExpansionButton(
				PersonKey.STRATEGY,
				!this.getPerson.hasCountry()
			);
			this.disabledExpansionButton(
				PersonKey.USES_CASES,
				!this.getPerson.hasStrategy()
			);
			this.disabledExpansionButton(
				PersonKey.GEO,
				!this.getPerson.hasUsesCases()
			);
			this.disabledExpansionButton(
				PersonKey.POIS,
				!this.getPerson.hasGeoSelected()
			);
			this.disabledExpansionButton(
				PersonKey.OOH,
				!this.getPerson.hasUsesCases()
			);
			this.disabledExpansionButton(
				PersonKey.PRIVATE,
				!this.getPerson.hasUsesCases()
			);
			this.disabledExpansionButton(
				PersonKey.POS,
				!this.getPerson.isPosArea() && !this.getPerson.hasUsesCases()
			);
		},
	},
	watch: {
		"getPerson.country_global": {
			async handler(val: SelectedDataEntity, oldVal: SelectedDataEntity) {
				if (this.isGettingFromStored) return;

				this.getQueryParams.setCountryCode(val?.id);
				if (isEqual(val, oldVal)) return;

				this.removeParamsFromURL();

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.COUNTRY_GLOBAL);

				// Reset de datos excepto country_global
				await this.resetPerson({ country: val });

				this.fetchUseCases();

				/**
				 * Actualizar la visibilidad de
				 * las secciones de filtros y los botones de accion
				 * segun la seleccion de {strategy} y {uses_cases}
				 */
				await this.getPerson.updateVisibility();

				/**
				 * Verificar si los filtros en cada tab es valido
				 * si es valido cierra el tab actual y abre el proximo
				 */
				await this.verifyActivePanelByKey(
					PersonKey.COUNTRY_GLOBAL,
					val
				);

				// Actualizar Mapa de GeoAnalisis
				this.handleSubmitCarto();

				await this.updateExpansionSections();
			},
		},
		"getPerson.strategy": {
			async handler(val: SelectedDataEntity, oldVal: SelectedDataEntity) {
				if (this.isGettingFromStored) return;
				if (isEqual(val, oldVal)) return;

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.STRATEGY);

				// Reset data
				await this.getPerson.changeTabSelection(
					new TabSelectionOptionalEntity({
						uses_cases: true,
						geo: true,
						pois: true,
						ooh: true,
						privates: true,
						pos: true,
					})
				);

				await this.getPerson.updateVisibility();

				await this.verifyActivePanelByKey(PersonKey.STRATEGY, val);

				await this.updateExpansionSections();
			},
		},
		"getPerson.uses_cases": {
			async handler(val: SelectedDataEntity) {
				if (this.isGettingFromStored) return;

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.USES_CASES);

				await this.getPerson.changeTabSelection(
					new TabSelectionOptionalEntity({
						geo: true,
						pois: true,
						ooh: true,
						privates: true,
						pos: true,
					})
				);

				await this.getPerson.updateVisibility();

				await this.verifyActivePanelByKey(PersonKey.USES_CASES, val);

				await this.updateExpansionSections();
			},
		},
		"getPerson.geo.selected": {
			async handler(val: SelectedDataEntity) {
				if (this.isGettingFromStored) return;

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.GEO);

				await this.getPerson.changeTabSelection(
					new TabSelectionOptionalEntity()
				);

				await this.updateExpansionSections();

				// habilitar/deshabilitar boton de Analyze
				await this.updateButtonActionDisable(true);
			},
			deep: true,
		},
		"getPerson.pois.selected": {
			async handler(val, oldVal) {
				if (this.isGettingFromStored) return;

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.POIS);

				await this.verifyActivePanelByKey(PersonKey.POIS, val);

				// habilitar/deshabilitar boton de Analyze
				await this.updateButtonActionDisable(true);

				await this.updateExpansionSections();
			},
			deep: true,
		},
		"getPerson.privates.selected": {
			async handler(val, oldVal) {
				if (this.isGettingFromStored) return;

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.PRIVATE);

				// habilitar/deshabilitar boton de Analyze
				await this.updateButtonActionDisable(true);

				await this.updateExpansionSections();
			},
			deep: true,
		},
		"getPerson.ooh.selected": {
			async handler(val, oldVal) {
				if (this.isGettingFromStored) return;

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.OOH);

				await this.verifyActivePanelByKey(PersonKey.OOH, val);

				// habilitar/deshabilitar boton de Analyze
				await this.updateButtonActionDisable(true);

				await this.updateExpansionSections();
			},
			deep: true,
		},
		"getPerson.pos.selected": {
			async handler(val, oldVal) {
				if (this.isGettingFromStored) return;

				await this.updateFiltersClear();

				this.initPoisActions(PersonKey.POS);

				// await this.verifyActivePanelByKey(PersonKey.POS, val);

				// habilitar/deshabilitar boton de Analyze
				await this.updateButtonActionDisable(true);

				await this.updateExpansionSections();
			},
			deep: true,
		},
		activePanel(data) {
			if (data > 0) {
				this.scrollToTop();
			}
		},
	},
});
